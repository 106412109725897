/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { JobFile } from 'app/shared/models';

export enum JobFilesActionTypes {
  GetJobFiles = '[JobFiles] Get Job Files',
  GetJobFilesSuccess = '[JobFiles] Get Job Files Success',
  GetJobFilesFailure = '[JobFiles] Get Job Files Failure',
  SetCurrentJobFilesFolderPath = '[JobFiles] Set Current Folder Path',
  SetSelectedJobFilesFolderPath = '[JobFiles] Set Selected Folder Path',
  SetSelectedJobFilesFilePath = '[JobFiles] Set Selected File Path',
  ShowCreateJobFilesFolderDialog = '[JobFiles] Show Create Folder Dialog',
  CreateJobFilesFolder = '[JobFiles] Create Job Folder',
  CreateJobFilesFolderSuccess = '[JobFiles] Create Job Folder Success',
  CreateJobFilesFolderFailure = '[JobFiles] Create Job Folder Failure',
  CreateJobFile = '[JobFiles] Create Job File',
  CreateJobFileSuccess = '[JobFiles] Create Job File Success',
  CreateJobFileFailure = '[JobFiles] Create Job File Failure',
  DeleteJobFileFolders = '[JobFiles] Delete Folders',
  DeleteJobFileFoldersSuccess = '[JobFiles] Delete Folders Success',
  DeleteJobFilesFoldersFailure = '[JobFiles] Delete Folders Failure',
  DeleteJobFiles = '[JobFiles] Delete Files',
  DeleteJobFilesSuccess = '[JobFiles] Delete Files Success',
  DeleteJobFilesFailure = '[JobFiles] Delete Files Failure',
  MoveJobFiles = '[JobFiles] Move Files',
  MoveJobFilesSuccess = '[JobFiles] Move Files Success',
  SetJobFilesSortField = '[JobFiles] Set Sort Field',
  SetJobFilesSortOrder = '[JobFiles] Set Sort Order',
  ToggleJobFilesFileSelected = '[JobFiles] Toggle File Selected',
  ToggleJobFilesFolderSelected = '[Jobs] Toggle Folder Selected',
  SelectJobFiles = '[JobFiles] Select Files',
  DeselectJobFiles = '[JobFiles] Deselect Files',
  SelectJobFilesFolders = '[JobFiles] Select Folders',
  DeselectJobFilesFolders = '[JobFiles] Deselect Folders',
  ShowRenameJobFileDialog = '[JobFiles] Show Rename File Dialog',
  RenameJobFile = '[JobFiles] Rename File',
  RenameJobFileSuccess = '[JobFiles] Rename File Success',
  RenameJobFileFailure = '[JobFiles] Rename File Failure',
  ShowRenameJobFilesFolderDialog = '[JobFiles] Show Rename Folder Dialog',
  RenameJobFilesFolder = '[JobFiles] Rename Folder',
  RenameJobFilesFolderSuccess = '[JobFiles] Rename Folder Success',
  RenameJobFilesFolderFailure = '[JobFiles] Rename Folder Failure',

  DownloadMultipleJobFiles = '[JobFiles] Download Multiple Job Files',
  DownloadMultipleJobFilesSuccess = '[JobFiles API] Download Multiple Job Files Success',
  DownloadMultipleJobFilesFailure = '[JobFiles API] Download Multiple Job Files Failure',
}

export const downloadMultipleJobFiles = createAction(
  JobFilesActionTypes.DownloadMultipleJobFiles,
  props<{ jobId: string; files: JobFile[] }>(),
);

export const downloadMultipleJobFilesSuccess = createAction(
  JobFilesActionTypes.DownloadMultipleJobFilesSuccess,
  props<{ blob: Blob; filename: string }>(),
);

export const downloadMultipleJobFilesFailure = createAction(
  JobFilesActionTypes.DownloadMultipleJobFilesFailure,
  props<{ error: string }>(),
);

export const showCreateJobFilesFolderDialog = createAction(
  JobFilesActionTypes.ShowCreateJobFilesFolderDialog,
  props<{ jobId: string; path: string }>(),
);

export const showRenameJobFilesFolderDialog = createAction(
  JobFilesActionTypes.ShowRenameJobFilesFolderDialog,
  props<{ jobId: string; path: string }>(),
);

export const renameJobFilesFolder = createAction(
  JobFilesActionTypes.RenameJobFilesFolder,
  props<{ jobId: string; oldPath: string; newPath: string }>(),
);

export const renameJobFilesFolderSuccess = createAction(
  JobFilesActionTypes.RenameJobFilesFolderSuccess,
  props<{ files: JobFile[] }>(),
);

export const renameJobFilesFolderFailure = createAction(
  JobFilesActionTypes.RenameJobFilesFolderFailure,
  props<{ error: string }>(),
);

export const createJobFilesFolder = createAction(
  JobFilesActionTypes.CreateJobFilesFolder,
  props<{ jobId: string; path: string }>(),
);

export const createJobFilesFolderSuccess = createAction(
  JobFilesActionTypes.CreateJobFilesFolderSuccess,
  props<{ file: JobFile }>(),
);

export const createJobFilesFolderFailure = createAction(
  JobFilesActionTypes.CreateJobFilesFolderFailure,
  props<{ error: string }>(),
);

export const showRenameJobFileDialog = createAction(
  JobFilesActionTypes.ShowRenameJobFileDialog,
  props<{ file: JobFile }>(),
);

export const renameJobFile = createAction(
  JobFilesActionTypes.RenameJobFile,
  props<{ file: JobFile }>(),
);

export const renameJobFileSuccess = createAction(
  JobFilesActionTypes.RenameJobFileSuccess,
  props<{ file: JobFile }>(),
);

export const renameJobFileFailure = createAction(
  JobFilesActionTypes.RenameJobFileFailure,
  props<{ error: string }>(),
);

export const setJobFilesSortField = createAction(
  JobFilesActionTypes.SetJobFilesSortField,
  props<{ sortField: string }>(),
);

export const setJobFilesSortOrder = createAction(
  JobFilesActionTypes.SetJobFilesSortOrder,
  props<{ sortOrder: 'asc' | 'desc' }>(),
);

export const getJobFiles = createAction(
  JobFilesActionTypes.GetJobFiles,
  props<{ jobId: string }>(),
);

export const getJobFilesSuccess = createAction(
  JobFilesActionTypes.GetJobFilesSuccess,
  props<{ files: JobFile[] }>(),
);

export const getJobFilesFailure = createAction(
  JobFilesActionTypes.GetJobFilesFailure,
  props<{ error: string }>(),
);

export const moveJobFiles = createAction(
  JobFilesActionTypes.MoveJobFiles,
  props<{ fileIds: string[]; toPath: string }>(),
);

export const moveJobFilesSuccess = createAction(
  JobFilesActionTypes.MoveJobFilesSuccess,
  props<{ fileIds: string[]; toPath: string }>(),
);

export const setJobFilesCurrentFolderPath = createAction(
  JobFilesActionTypes.SetCurrentJobFilesFolderPath,
  props<{ path: string }>(),
);

export const setJobFilesSelectedFilePath = createAction(
  JobFilesActionTypes.SetSelectedJobFilesFilePath,
  props<{ path: string }>(),
);

export const selectJobFilesFolderPaths = createAction(
  JobFilesActionTypes.SelectJobFilesFolders,
  props<{ paths: string[] }>(),
);

export const deselectJobFilesFolderPaths = createAction(
  JobFilesActionTypes.DeselectJobFilesFolders,
  props<{ paths: string[] }>(),
);

export const selectFileIds = createAction(
  JobFilesActionTypes.SelectJobFiles,
  props<{ ids: string[] }>(),
);

export const deselectFileIds = createAction(
  JobFilesActionTypes.DeselectJobFiles,
  props<{ ids: string[] }>(),
);

export const toggleJobFilesFolderSelected = createAction(
  JobFilesActionTypes.ToggleJobFilesFolderSelected,
  props<{ path: string }>(),
);

export const toggleJobFilesFileSelected = createAction(
  JobFilesActionTypes.ToggleJobFilesFileSelected,
  props<{ id: string }>(),
);

export const createJobFile = createAction(
  JobFilesActionTypes.CreateJobFile,
  props<{ file: JobFile }>(),
);

export const createJobFileSuccess = createAction(
  JobFilesActionTypes.CreateJobFileSuccess,
  props<{ file: JobFile }>(),
);

export const createJobFileFailure = createAction(
  JobFilesActionTypes.CreateJobFileFailure,
  props<{ error: string }>(),
);

export const deleteFolders = createAction(
  JobFilesActionTypes.DeleteJobFileFolders,
  props<{ jobId: string; paths: string[] }>(),
);

export const deleteFoldersSuccess = createAction(
  JobFilesActionTypes.DeleteJobFileFoldersSuccess,
  props<{ ids: string[] }>(),
);

export const deleteFoldersFailure = createAction(
  JobFilesActionTypes.DeleteJobFilesFoldersFailure,
  props<{ error: string }>(),
);

export const deleteFiles = createAction(
  JobFilesActionTypes.DeleteJobFiles,
  props<{ jobId: string; ids: string[] }>(),
);

export const deleteFilesSuccess = createAction(
  JobFilesActionTypes.DeleteJobFilesSuccess,
  props<{ ids: string[] }>(),
);

export const deleteFilesFailure = createAction(
  JobFilesActionTypes.DeleteJobFilesFailure,
  props<{ error: string }>(),
);
