/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import {
  AccountTeamMember,
  TeamMemberData,
} from 'app/shared/models/form-ui.models';

import * as fromActions from '../actions/team-settings.actions';
import * as fromSelectors from '../selectors/team-settings.selectors';
import {
  AddTeamMember,
  SettingsTeamMemberAccessLevelUpdate,
  SettingsTeamMemberDelete,
  SettingsTeamMemberMergedRoles,
  SettingsTeamMemberModifyRoles,
} from 'app/shared/models/eazimate.models';

@Injectable({
  providedIn: 'root',
})
export class TeamSettingsFacade {
  availableSeats$ = this.store.select(
    fromSelectors.selectNumberOfAvailableSeats,
  );
  teamCommon$ = this.store.select(fromSelectors.selectTeamCommon);
  team$ = this.store.select(fromSelectors.selectTeam);
  isLoading$ = this.store.select(fromSelectors.selectIsLoading);
  isInitialised$ = this.store.select(fromSelectors.selectIsInitialised);
  error$ = this.store.select(fromSelectors.selectError);
  numberOfOwnerAccounts$ = this.store.select(
    fromSelectors.selectNumberOfOwnerAccounts,
  );

  selectedTeamMember$ = this.store.select(
    fromSelectors.selectSelectedTeamTeamMember,
  );

  profileSaved$: Observable<Action>;
  credentialsSaved$: Observable<Action>;
  inviteTeamMemberChange$: Observable<Action>;
  updateTeamMemberAccessLevelSuccess$: Observable<Action>;

  initialise(): void {
    this.store.dispatch(fromActions.getTeam());
    this.store.dispatch(fromActions.getCommonTeamMemberData());
  }

  editTeamMemberRoles(teamMemberId: string, teamMemberTypeId: number): void {
    const payload: SettingsTeamMemberMergedRoles = {
      teamMemberId,
      teamMemberTypeId,
    };
    this.store.dispatch(
      fromActions.getSelectedTeamMemberMergedRoles({ payload }),
    );
  }

  addExtraSeat(): void {
    this.store.dispatch(fromActions.addExtraSeat());
  }

  getAvailableSeats() {
    this.store.dispatch(fromActions.getAvailableSeats());
  }

  inviteTeamMember(invite: AddTeamMember): void {
    this.store.dispatch(fromActions.inviteTeamMember({ payload: invite }));
  }

  updateTeamMemberRole(payload: TeamMemberData): void {
    this.store.dispatch(fromActions.updateTeamMemberRole({ payload }));
  }

  updateTeamMemberRoles(payload: SettingsTeamMemberModifyRoles): void {
    this.store.dispatch(fromActions.updateTeamMemberRoles({ payload }));
  }

  updateTeamMemberAccessLevel(
    payload: SettingsTeamMemberAccessLevelUpdate,
  ): void {
    this.store.dispatch(fromActions.updateTeamMemberAccessLevel({ payload }));
  }

  removeTeamMember(payload: SettingsTeamMemberDelete): void {
    this.store.dispatch(fromActions.removeTeamMember({ payload }));
  }

  constructor(
    private store: Store,
    actions$: Actions,
  ) {
    this.updateTeamMemberAccessLevelSuccess$ = actions$.pipe(
      ofType(fromActions.updateTeamMemberAccessLevelSuccess),
    );
    this.inviteTeamMemberChange$ = actions$.pipe(
      ofType(
        fromActions.inviteTeamMemberSuccess,
        fromActions.inviteTeamMemberFailure,
        fromActions.removeTeamMemberSuccess,
        fromActions.removeTeamMemberFailure,
        fromActions.addExtraSeatSuccess,
        fromActions.addExtraSeatFailure,
      ),
    );
  }
}
