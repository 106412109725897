import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BillingService } from 'app/core/billing/billing.service';
import { AccountUserFacade } from 'app/shared/store/facades';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { BillingStatus } from './billing-settings.types';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { AvailablePlan } from 'app/shared/models';
import { FuseConfig, FuseConfigService } from '@fuse/services/config';
import { ChangePlanDialogComponent } from '../change-plan-dialog/change-plan-dialog.component';
import { ChangePlanCostsDialogComponent } from '../change-plan-costs-dialog/change-plan-costs-dialog.component';

import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { ManageExtraSeatsDialogComponent } from '../manage-extra-seats-dialog/manage-extra-seats-dialog.component';

@Component({
  selector: 'app-billing-settings',
  templateUrl: './billing-settings.component.html',
  styleUrls: ['./billing-settings.component.scss'],
  standalone: true,
  imports: [FormsModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BillingSettingsComponent implements OnInit {
  availablePlans: AvailablePlan[] = [];
  accountCancelled: boolean = false;
  unsubscriber$ = new Subject<void>();

  isReadOnly =
    this.async
      .transform(this.accountUserFacade.activeAccount$)
      .roles.filter(
        (r) =>
          r.name.toLocaleLowerCase() === 'settingsbilling' &&
          r.accessLevel === 0,
      ).length === 1;

  elementsOptions: StripeElementsOptions =
    {
      locale: 'en',
      appearance: {
        theme: 'stripe',
        variables: { colorPrimaryText: '#262626' }
      },
    };
  createSubscriptionResponse: {
    planId,
    customerId,
    subscriptionId,
    priceId,
    clientSecret
  };

  constructor(
    private billingService: BillingService,
    public accountUserFacade: AccountUserFacade,
    private confirm: FuseConfirmationService,
    private async: AsyncPipe,
    private dialogRef: MatDialogRef<PaymentDialogComponent>,
    public dialog: MatDialog,
    private fuseConfigService: FuseConfigService,
  ) {
  }

  private elementsSetup = (response) => {
    //get our client secret and use that to initialize stripe elements
    if (response.clientSecret) {
      this.elementsOptions.clientSecret = response.clientSecret;

      const dialogRef = this.dialog.open(PaymentDialogComponent, {
        data: {
          elementsOptions: this.elementsOptions,
          createSubscriptionResponse: response
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }
  };

  getManagementPortal$ = this.billingService.getManagementPortal().pipe(
    tap((response) => {
      if (response.url) {
        window.location.href = response.url;
      }
    }),
  );
  ngOnInit(): void {

    this.accountUserFacade.loggedInUser$
      .pipe(
        map((user) => {
          const currentPlan = user.account?.accountPlans?.filter(
            (x) => x.current,
          );

          if (currentPlan.length == 1) {
            this.accountCancelled =
              currentPlan[0].paymentProviderAccountStatus ===
              BillingStatus.Cancelled;
          }

          this.availablePlans = user.availablePlans;
          console.log('available plans', this.availablePlans);
        }),
      )
      .subscribe();

    this.accountUserFacade.accountCancelled$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: any) => {
        this.accountCancelled = true;
      });

    // Subscribe to config changes
    this.fuseConfigService.config$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((config: FuseConfig) => {
        switch (config.scheme) {
          case 'dark': {
            this.elementsOptions.appearance.theme = 'night';
            break;
          }
          case 'light': {
            this.elementsOptions.appearance.theme = 'stripe';
            break;
          }
          case 'auto': {
            this.elementsOptions.appearance.theme = 'night';
            break;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  onSubmit() {
    this.getManagementPortal$.subscribe();
  }

  handleOnManageExtraSeats($event: any) {
    const dialogRef = this.dialog.open(ManageExtraSeatsDialogComponent, {
      autoFocus: false,
    });

    dialogRef.afterClosed()
      .subscribe((result: any) => {
        if (result === 'Confirm') {
          this.billingService.removeExtraSeat()
            .subscribe(numAvailableSeats => {
              console.log('removed seat', numAvailableSeats);
            });

        }
      });
  }
  handleOnChangePlanClicked($event: any) {
    const dialogRef = this.dialog.open(ChangePlanDialogComponent, {
      autoFocus: false,
      data: {
        availablePlans: this.availablePlans,
        accountCancelled: this.accountCancelled,
      },
    });

    dialogRef.afterClosed().subscribe((plan: AvailablePlan) => {
      if (!plan) {
        return;
      }

      if (this.accountCancelled) {
        this.confirm
          .open({
            title: `Subscribe to ${plan.planName}?`,
            message: 'Do you want to continue?',
            icon: {
              name: 'heroicons_outline:exclamation-triangle',
              color: 'warn',
            },
            actions: {
              cancel: { label: 'Cancel' },
              confirm: { label: 'Continue', color: 'warn' },
            },
          })
          .afterClosed()
          .pipe(filter((result) => result === 'confirmed'))
          .subscribe(() => {
            this.billingService
              .resubscribe(plan.planId)
              .subscribe((response) => {
                console.log(response);
                this.elementsSetup(response);
              });
          });
      } else {
        this.billingService
          .getSwitchPlanCosts(plan.planId)
          .subscribe((response) => {
            console.log(response);
            // this.accountUserFacade.loginAccountUser();

            const dialogRef = this.dialog.open(ChangePlanCostsDialogComponent, {
              data: {
                changes: response,
              },
            });

            dialogRef
              .afterClosed()
              .pipe(filter((result) => result === 'Confirm'))
              .subscribe((result) => {
                console.log('result', result);

                this.billingService
                  .switchPlan(plan.planId)
                  .subscribe((response) => {
                    console.log(response);
                    this.accountUserFacade.loginAccountUser();
                  });
              });
          });
      }
    });
  }
}
