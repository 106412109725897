<div class="absolute inset-0 flex flex-col min-w-0">
  <div class="flex-auto h-full bg-card dark:bg-transparent">
    <div class="flex flex-col h-full bg-gray-100 dark:bg-transparent">
      <div class="flex flex-col p-6 py-0">
        <div
          class="flex lg:flex-row lg:items-center flex-col items-start justify-between mt-2"
          *ngIf="facade.job$ | async; let job"
        >
          <div class="flex flex-row items-center mb-2 mr-4">
            <button
              class="order-0 sm:mr-3 xs:mr-auto px-2 min-w-3 min-h-3 md:w-9 md:h-9 sm:w-6 sm:h-6 rounded"
              [color]="'primary'"
              [matTooltip]="
                backNavigationService?.tooltip
                  ? backNavigationService.tooltip
                  : (this.facade.job$ | async).jobType === jobType.Job
                    ? 'Back to Job list'
                    : 'Back to Estimate list'
              "
              (click)="back()"
              mat-flat-button
            >
              <mat-icon [svgIcon]="'heroicons_outline:arrow-left'"></mat-icon>
            </button>
            <div
              class="sm:order-1 xs:order-first xs:w-full mr-auto sm:w-auto sm:mb-0 xs:mb-4 tracking-tight leading-none"
            >
              <div
                class="xs:text-l flex md:text-3xl sm:text-xl xs:font-medium sm:font-extrabold"
                *ngIf="facade.title$ | async"
              >
                <span class="mt-1"> {{ facade.title$ | async }}</span>
                <app-job-status-pill
                  [class]="'py-2.5 rounded-xl'"
                  class="ml-4 flex items-center"
                  [status]="(this.facade.job$ | async).status"
                >
                </app-job-status-pill>
              </div>
              <div *ngIf="(facade.isLoaded$ | async) === false">&nbsp;</div>
              <div
                class="text-secondary"
                *ngIf="
                  (estimateDetailFacade.isLoaded$ | async) && isEstimateEstimate
                "
              >
                {{
                  "Version " +
                    (estimateDetailFacade.estimate$ | async).version +
                    " - " +
                    ((estimateDetailFacade.estimate$ | async).created
                      | date: "dd MMM yyyy")
                }}
              </div>
            </div>
          </div>

          <div
            class="flex flex-row items-center mb-2 lg:w-fit lg:justify-end w-full justify-between"
          >
            <div
              *ngIf="
                (currentChild === 'list' || currentChild === 'info') &&
                !isReadOnlyMode &&
                (this.facade.job$ | async).jobType === jobType.Job
              "
            >
              <button
                class="mr-4 text-xs rounded-xl min-h-8 h-8 px-4"
                *ngIf="canResetToEstimate$ | async"
                [color]="'primary'"
                (click)="backToEstimateStatus()"
                mat-stroked-button
              >
                <span class="xs:hidden sm:block ml-2 mr-1">
                  Set job back to estimate
                </span>
              </button>
            </div>

            <div class="xs:mr-2 sm:mr-4" *ngIf="currentChild === 'estimate'">
              <app-status-toggle
                *ngIf="(this.facade.job$ | async).jobType === jobType.Estimate"
                [statuses]="estimateStatusesList"
                [selected]="(facade.job$ | async).status"
                (select)="updateJobStatus($event)"
              ></app-status-toggle>
            </div>
            <div
              class="xs:mr-2 sm:mr-4"
              id="estimate-status-sections"
              *ngIf="
                (currentChild === 'estimate-info' ||
                  currentChild === 'settings') &&
                (this.facade.job$ | async).jobType === jobType.Estimate
              "
            >
              <app-status-toggle
                *ngIf="(estimateDetailFacade.estimate$ | async)?.status"
                [type]="'estimate'"
                [statuses]="estimatesEstimateStatusesList"
                [selected]="(estimateDetailFacade.estimate$ | async)?.status"
                (select)="updateEstimateStatus($event)"
              ></app-status-toggle>
            </div>
            <!--            <div class="xs:mr-2 sm:mr-4" *ngIf="currentChild === 'info'">-->
            <!--              <app-status-toggle-->
            <!--                *ngIf="(this.facade.job$ | async).jobType === jobType.Job"-->
            <!--                [statuses]="jobStatusesList"-->
            <!--                [selected]="(facade.job$ | async).status"-->
            <!--                (select)="updateJobStatus($event)"-->
            <!--              ></app-status-toggle>-->
            <!--            </div>-->
            <div class="flex flex-row items-center">
              <button
                class="xs:ml-2 sm:ml-4 order-3 text-xs rounded-xl min-h-8 h-8 px-4"
                *ngIf="currentChild === 'info' && !isReadOnlyMode"
                [disabled]="(facade.isLoaded$ | async) === false"
                [color]="'primary'"
                (click)="edit(job)"
                mat-flat-button
              >
                <mat-icon [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
                <span class="ml-2 mr-1">Edit</span>
              </button>
              <ng-container *ngIf="facade.job$ | async; let job">
                <button
                  class="xs:ml-2 sm:ml-4 order-4 text-xs rounded-xl min-h-8 h-8 px-4"
                  *ngIf="currentChild === 'info' && !isReadOnlyMode"
                  [disabled]="(facade.isLoaded$ | async) === false"
                  [color]="'accent'"
                  (click)="delete(job.id, job.jobType === jobType.Job)"
                  mat-flat-button
                >
                  <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                  <span class="ml-2 mr-1">Delete</span>
                </button>
              </ng-container>

              <!-- Create new Notes button -->
              <button
                class="order-5 text-xs rounded-xl min-h-8 h-8 px-4"
                *ngIf="currentChild === 'notes' && !isReadOnlyMode"
                (click)="createNewNote(job.id)"
                mat-flat-button
                color="primary"
              >
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="xs:hidden sm:block ml-2 mr-1">Add Note</span>
              </button>
              <!--Create new Notes button  end            -->

              <!-- add New Invoice button -->
              <button
                id="add-invoice"
                class="order-5 text-xs rounded-xl min-h-8 h-8 px-4"
                *ngIf="
                  currentChild === 'invoices' &&
                  !nestedModulesAccess.limitedInvoicing
                "
                (click)="createNewInvoice(job.id)"
                mat-flat-button
                color="primary"
              >
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="xs:hidden sm:block ml-2 mr-1">Add Invoice</span>
              </button>

              <!-- add New Template button -->
              <button
                class="order-5 text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                id="estimate-create-template-button"
                *ngIf="currentChild === 'estimate-info' && !isReadOnlyMode"
                [disabled]="
                  (canCreateTemplate$ | async) === false ||
                  (facade.isLoaded$ | async) === false
                "
                (click)="createNewTemplate(job)"
                mat-flat-button
                color="accent"
              >
                <mat-icon
                  [svgIcon]="'heroicons_outline:document-duplicate'"
                ></mat-icon>
                <span class="xs:hidden sm:block ml-2 mr-1"
                  >Create template</span
                >
              </button>

              <!-- add Accept button in Job Estimate -->
              <button
                class="order-5 text-xs rounded-xl min-h-8 h-8 px-4"
                *ngIf="currentChild === 'estimate-info' && !isReadOnlyMode"
                (click)="acceptEstimate()"
                [disabled]="
                  (estimateDetailFacade.editing$ | async) ||
                  (estimateDetailFacade.estimate$ | async)?.status !== 20
                "
                mat-flat-button
                color="primary"
              >
                <span class="mx-2">Accept</span>
              </button>

              <!-- add New Template button in Job Estimate -->
              <button
                class="order-5 text-xs rounded-xl min-h-8 h-8 px-4 border-primary mr-4"
                *ngIf="currentChild === 'estimate' && !isReadOnlyMode"
                [color]="'accent'"
                (click)="createNewTemplate(job)"
                mat-flat-button
              >
                <mat-icon
                  [svgIcon]="'heroicons_outline:document-duplicate'"
                ></mat-icon>
                <span class="ml-2">Create template</span>
              </button>

              <!-- add New CallForward Item -->
              <button
                class="order-5 text-xs rounded-xl min-h-8 h-8 px-4 border-primary"
                *ngIf="currentChild === 'call-forward' && !isReadOnlyMode"
                [disabled]="
                  (callForwardFacade.editIndex$ | async) !== null ||
                  (callForwardFacade.isNew$ | async)
                "
                (click)="this.callForwardFacade.fireCreateNewEvent(true)"
                mat-flat-button
                color="primary"
              >
                <mat-icon
                  [svgIcon]="'heroicons_outline:plus-circle'"
                ></mat-icon>
                <span class="ml-2">Add</span>
              </button>

              <!-- Complete Estimate status bar -->
              <div
                class="flex flex-row"
                *ngIf="currentChild === 'complete-estimate'"
              >
                <app-status-toggle
                  *ngIf="jobCostingFacade.jobCostingStatus | async"
                  [type]="'jobCosting'"
                  [statuses]="jobCostingStatusesList"
                  [disable]="!((jobCostingFacade.jobCostingOriginal$ | async)?.items.length) ? JobStatus.Job_Completed : null"
                  [selected]="jobCostingFacade.jobCostingStatus | async"
                  (select)="updateJobCostingStatus($event)"
                ></app-status-toggle>
                <button
                  class="ml-4 text-xs rounded-xl min-h-8 h-8 px-4"
                  *ngIf="
                    !isReadOnlyMode &&
                    (jobCostingFacade.jobCostingStatus | async) === 60
                  "
                  (click)="acceptEstimate()"
                  [disabled]="!((jobCostingFacade.jobCostingOriginal$ | async)?.items.length) ? true : (jobCostingFacade.editing$ | async)"
                  mat-flat-button
                  color="primary"
                >
                  <span class="mx-2">Accept</span>
                </button>
              </div>

              <!-- add New Variation Item -->
              <button
                id="add-variation"
                class="order-5 text-xs rounded-xl min-h-8 h-8 px-4 border-primary"
                *ngIf="currentChild === 'list' && !isReadOnlyMode && (facade.isLoaded$ | async) && (jobCostingFacade.isLoaded$ | async)"
                [disabled]="isVariationHasDraft || !completeEstimateHasItems"
                (click)="addNewVariation(job)"
                mat-flat-button
                color="primary"
              >
                <mat-icon
                  [svgIcon]="'heroicons_outline:plus-circle'"
                ></mat-icon>
                <span class="ml-2">Add Variation</span>
              </button>

              <!--Create new Notes button  end            -->
              <ng-container
                *ngIf="
                  currentChild === 'orders' &&
                  !nestedModulesAccess.limitedOrdering
                "
              >
                <button
                  id="create-orders"
                  [disabled]="
                    !(jobOrderSummaryFacade.itemsToOrderCount$ | async)
                  "
                  class="order-5 xs:mr-2 sm:mr-4 pl-2 text-xs rounded-xl min-h-8 h-8 pr-4"
                  [color]="'primary'"
                  (click)="createOrder()"
                  mat-flat-button
                >
                  <mat-icon
                    [svgIcon]="'heroicons_outline:plus-small'"
                  ></mat-icon>
                  <span class="ml-2 mr-1">Create orders</span>
                </button>
                <!--                <button-->
                <!--                  [disabled]="!(jobOrderSummaryFacade.itemsToOrderCount$ | async)"-->
                <!--                  class="order-5 xs:mr-2 sm:mr-4 pl-2 text-xs rounded-xl min-h-8 h-8 pr-4"-->
                <!--                  [color]="'primary'"-->
                <!--                  [routerLink]="['/jobs/order', { jobId: jobId }]"-->
                <!--                  mat-flat-button-->
                <!--                >-->
                <!--                  <mat-icon [svgIcon]="'heroicons_outline:plus-small'"></mat-icon>-->
                <!--                  <span class="ml-2 mr-1">Add Order</span>-->
                <!--                </button>-->
                <button
                  id="create-extra-over-order"
                  class="order-5 pl-2 text-xs rounded-xl min-h-8 h-8 pr-4"
                  [color]="'primary'"
                  [routerLink]="[
                    '/jobs/order',
                    { jobId: jobId, extraOverOrder: true }
                  ]"
                  mat-flat-button
                >
                  <mat-icon
                    [svgIcon]="'heroicons_outline:plus-small'"
                  ></mat-icon>
                  <span class="ml-2 mr-1">Add Extra / Over Order</span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <ng-container *ngIf="(hideMenu$ | async) === false">
          <!-- <pre> {{ menuItems$ | async | json }} </pre> -->
          <nav class="mb-3" mat-tab-nav-bar [tabPanel]="tabPanel">
            <a
              #rla="routerLinkActive"
              *ngFor="let link of menuItems$ | async"
              [routerLink]="link.route"
              [active]="rla.isActive"
              mat-tab-link
              routerLinkActive
            >
              {{ link.text }}
            </a>
          </nav>
        </ng-container>
      </div>
      <mat-tab-nav-panel class="h-full flex overflow-hidden flex-col" #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </div>
</div>
