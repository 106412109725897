export const environment = {
  production: false,
  urls: {
    // api: 'https://app-hub-eazimate-dshngnvrop3ow.azurewebsites.net',
    // api: 'http://localhost:8080',
    api: 'https://api-eazimate-dev-b5hchvb9a5cbdubz.australiaeast-01.azurewebsites.net',
    noticeApi: 'https://func-sendgird-dshngnvrop3ow.azurewebsites.net',
  },
  storage: {
    publicAssets: 'st0public0dshngnvrop3ow',
    privateAssets: 'st0private0dshngnvrop3o',
  },
  appInsights: {
    connectionString:
      'InstrumentationKey=51a4514f-c8a7-4614-bb49-a5be0f326bf1;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/;ApplicationId=3dd03162-1d55-4b6a-8478-cc7f2d41ce10',
  },
  stripe:
    'pk_test_51JVpeEEgsCXh161omn7QdNFslgNI3kuedneTJu69EhhygeC7vtNTzga8qF43Yp4EgiFAdTnY3lXliu54AtPEJNSK00Bx2Z8Nnq',
  b2cAppSettings: {
    // apimUrl: 'https://app-hub-eazimate-dshngnvrop3ow.azurewebsites.net',
    // apimUrl: 'http://localhost:8080',
    apimUrl:
      'https://api-eazimate-dev-b5hchvb9a5cbdubz.australiaeast-01.azurewebsites.net',
    authorityDomain: 'easyestimateb2c.b2clogin.com',
    clientId: '5ec0beff-1999-4a54-ba76-9070f44a8f60',
    consentScopes: ['openid'],
    defaultSignin:
      'https://easyestimateb2c.b2clogin.com/easyestimate.com.au/B2C_1A_A-CUSTOM_SIGNUP_SIGNIN',
    b2cScopes: [
      '5ec0beff-1999-4a54-ba76-9070f44a8f60',
      'offline_access',
      'openid',
    ],
    policyNames: {
      signUpSignIn: 'B2C_1A_A-CUSTOM_SIGNUP_SIGNIN',
      forgotPassword: 'B2C_1A_A-PASSWORDRESET',
    },
  },
  speachToText: {
    subscriptionKey: '91f3c77bafa94614bca4603dbd62d592',
    region: 'eastus',
  },
  google: {
    analytics: 'G-YLQ9F9TTZX',
  },
  client:
    'pk_test_51JVpeEEgsCXh161omn7QdNFslgNI3kuedneTJu69EhhygeC7vtNTzga8qF43Yp4EgiFAdTnY3lXliu54AtPEJNSK00Bx2Z8Nnq',
};
