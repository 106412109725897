import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    importProvidersFrom,
    inject,
    isDevMode,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
    provideAnimations,
    provideNoopAnimations,
} from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withDisabledInitialNavigation,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { TranslocoService, provideTransloco } from '@ngneat/transloco';
import { appRoutes } from 'app/app.routes';
import { firstValueFrom } from 'rxjs';
// import { appRoutes } from 'app/app.routes-new';
import { BrowserModule } from '@angular/platform-browser';
import {
    BrowserCacheLocation,
    BrowserUtils,
    IPublicClientApplication,
    InteractionType,
    LogLevel,
    PublicClientApplication,
} from '@azure/msal-browser';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';

import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
} from '@azure/msal-angular';
import {
    IGoogleAnalyticsSettings,
    NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Action, ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { provideNgxStripe } from 'ngx-stripe';
import { PaymentRequiredModule } from './modules/auth/payment-required/payment-required.module';
import { effects, reducers } from './store';

import { environment } from '@env/environment';

export function loggerCallback(logLevel: LogLevel, message: string) {
    // console.log(message);
}

const initialNavigation =
    !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
        : withDisabledInitialNavigation();

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.b2cAppSettings.clientId,
            authority: environment.b2cAppSettings.defaultSignin,
            redirectUri: `${window.location.origin}`,
            postLogoutRedirectUri: `${window.location.origin}`,
            knownAuthorities: [environment.b2cAppSettings.authorityDomain],
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
            },
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(
        environment.b2cAppSettings.apimUrl,
        environment.b2cAppSettings.b2cScopes
    );
    protectedResourceMap.set(
        environment.urls.noticeApi,
        environment.b2cAppSettings.b2cScopes
    );
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: environment.b2cAppSettings.b2cScopes,
            authority: environment.b2cAppSettings.defaultSignin,
        },
    };
}

export function GoogleAnalyticsKey(): IGoogleAnalyticsSettings {
    const ga: IGoogleAnalyticsSettings = {
        trackingCode: environment.google.analytics,
    };

    return ga;
}

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state: any, action: Action): any {
        if (action.type === '[Auth] Confirm Logout') {
            state = undefined;
        }
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<any>[] = isDevMode()
    ? [storeFreeze, clearState]
    : [clearState];

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(
            appRoutes,
            initialNavigation,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
            // withDebugTracing(),
        ),
        importProvidersFrom(
            BrowserModule,
            MsalModule,
            StoreModule.forRoot(reducers, { metaReducers }),
            EffectsModule.forRoot(effects),
            StoreRouterConnectingModule.forRoot(),
            isDevMode() ? StoreDevtoolsModule.instrument() : [],
            PaymentRequiredModule,
            NgxGoogleAnalyticsModule.forRoot(''),
            NgxGoogleAnalyticsRouterModule
        ),
        provideNgxStripe(),
        provideNoopAnimations(),
        provideAnimations(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        { provide: Window, useValue: window },
        {
            provide: MSAL_INSTANCE,
            useFactory: () => MSALInstanceFactory(),
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: () => MSALGuardConfigFactory(),
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: () => MSALInterceptorConfigFactory(),
        },
        {
            provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
            useFactory: () => GoogleAnalyticsKey(),
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English',
                    },
                    {
                        id: 'tr',
                        label: 'Turkish',
                    },
                ],
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => firstValueFrom(translocoService.load(defaultLang));
            },
            multi: true,
        },

        // Fuse
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'classy',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
    ],
};
